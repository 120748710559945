import { Button, Input, notification } from "antd";
import { sendPasswordResetEmail } from "firebase/auth";
import { useState } from "react";
import { Link } from "react-router-dom";
import { auth } from "../../firebase/firebase-service";
import logo from "../../images/lockup_vert_black.png";
import "./auth.css";

function ForgotPassword() {
  const [email, setEmail] = useState("");

  const handleInputChange = (event: any) => {
    setEmail(event.target.value);
  };

  const handleForgotPassword = () => {
    if (email.length > 0) {
      sendPasswordResetEmail(auth, email)
        .then(() => {
          notification.open({
            type: "success",
            message: "Password Reset Sent!",
          });
        })
        .catch((err) => {
          notification.open({ type: "error", message: err.message });
        });
    } else {
      notification.open({ type: "error", message: "Email must be valid" });
    }
  };

  return (
    <div className="Auth">
      <div className="isoAuthContent">
        <Link to={"/"}>
          {String.fromCharCode(8592) + "  back to Aquifer login"}
        </Link>
        <img src={logo} alt="logo" className="logo" />
        <div className="isoFormHeadText">
          <h3>Forgot Your Password?</h3>
          <p>Enter your email and we will send you a reset link.</p>
        </div>

        <div className="isoAuthForm">
          <div className="isoInputWrapper">
            <Input
              name="email"
              size="large"
              placeholder="Email"
              value={email}
              onChange={handleInputChange}
            />
          </div>

          <div className="isoInputWrapper">
            <Button type="primary" onClick={handleForgotPassword}>
              Send Request
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
