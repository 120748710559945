import { deleteDoc, doc } from "firebase/firestore";
import { useContext } from "react";
import { firestore } from "../../../firebase/firebase-service";
import { Project } from "../../../models/models";
import { MotionboxService } from "../../../motionbox/motionbox.service";
import { ProjectContext } from "../../../provider/project.provider";
import { UserContext } from "../../../provider/user.provider";
import ContentLayout from "../common/contentLayout/contentLayout";

function Projects() {
  const projects = useContext(ProjectContext);
  const currentUser = useContext(UserContext);
  const uid = currentUser?.uid as string;
  const dir = `users/${uid}/videoEdits`;

  const thumbNailClick = (project: Project) => {
    MotionboxService.openMotionbox(project.id, project.projectData.videos);
  };

  const deleteItem = async (project: Project) => {
    const path = `${dir}/${project.id}`;
    const ref = doc(firestore, path);
    await deleteDoc(ref);
  };

  return (
    <ContentLayout
      title={"My Projects"}
      itemData={projects}
      thumbNailClick={thumbNailClick}
      deleteItem={deleteItem}
    />
  );
}

export default Projects;
