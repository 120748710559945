import { Menu, Divider, Button } from "antd";
import {
  HomeFilled,
  VideoCameraFilled,
  SettingFilled,
} from "@ant-design/icons";
import "./sidenav.css";
import logo from "../../../images/logo_horizontal.png";
import { auth } from "../../../firebase/firebase-service";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../../../provider/user.provider";

interface Props {
  newProject: () => void;
}

function SideNav({ newProject }: Props) {
  const currentUser = useContext(UserContext);

  const handleLogout = () => {
    auth.signOut();
  };

  const adminOption = () => {
    if (currentUser?.roles?.admin) {
      return (
        <Menu.Item key="admin" icon={<SettingFilled />}>
          <Link to={{ pathname: "", hash: "#admin" }}>Admin</Link>
        </Menu.Item>
      );
    }
  };

  return (
    <div className="sidenav">
      <div>
        <a href="https://www.aquifermotion.com/"><img src={logo} alt="logo" className="logo" style={{ width: "70%" }} /></a>
        <Menu
          theme="light"
          mode="inline"
          selectable={false}
          selectedKeys={[]}
          style={{ border: "none" }}
        >
          
          <Menu.Item key="home" icon={<HomeFilled />}>
            <Link to={{ pathname: "", hash: "#home" }}>Home</Link>
          </Menu.Item>
          <Menu.Item key="footage" icon={<VideoCameraFilled />}>
            <Link to={{ pathname: "", hash: "#footage" }}>Exports</Link>
          </Menu.Item>
          {adminOption()}
        </Menu>
        <Divider />
      </div>
      <div>
        <Button className="project-btn" type="primary" onClick={handleLogout}>
          Logout
        </Button>
      </div>
    </div>
  );
}

export default SideNav;
