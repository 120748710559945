import { getDatabase, ref, onValue } from "firebase/database";
import { createContext, useContext, useEffect, useState } from "react";
import { app } from "../firebase/firebase-service";
import { Queue, QueueError, QueueItem } from "../models/models";
import { UserContext } from "./user.provider";

export const QueueContext = createContext<Queue>({});

const QueueProvider = ({ children }: any) => {
  const currentUser = useContext(UserContext);
  const [queue, setQueue] = useState<Queue>({});

  useEffect(() => {
    if (!!currentUser) {
      const db = getDatabase(app);
      const uid = currentUser?.uid as string;

      const queueRef = ref(db, "render");
      onValue(queueRef, (snapshot) => {
        const data = snapshot.val();
        const { errors, queue, target } = data;
        const errorItems = [];
        const queueItems = [];
        for (const id in errors) {
          const { error, render } = errors[id];
          if (render.includes(`/${uid}/`)) {
            errorItems.push({
              key: id,
              error,
              render,
            } as QueueError);
          }
        }
        for (const id in queue) {
          const { renderName, thumbNail, path } = queue[id];
          if (path.includes(`/${uid}/`)) {
            queueItems.push({
              key: id,
              renderName,
              thumbNail,
              path,
            } as QueueItem);
          }
        }

        const queueDetails = {
          queued: queueItems,
          errors: errorItems,
        } as Queue;

        if (!!target && target.path.includes(`/${uid}/`)) {
          const { renderName, thumbNail, path } = target;
          queueDetails.target = {
            key: "target",
            path,
            renderName,
            thumbNail,
          } as QueueItem;
        }
        setQueue(queueDetails);
      });
    } else {
      setQueue({});
    }
  }, [currentUser]);

  return (
    <QueueContext.Provider value={queue}>{children}</QueueContext.Provider>
  );
};

export default QueueProvider;
