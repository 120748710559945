import { Carousel, Divider, Modal, Spin, Button } from "antd";
import { useContext, useState } from "react";
import { UserContext } from "../../../provider/user.provider";
// import welcomeImage from "../../../images/project_screenshot.png";
import "./home.css";
import { FootageContext } from "../../../provider/footage.provider";
import ThumbNail from "../common/thumbNail";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import { Video } from "../../../models/models";
import VideoComponent from "../common/videoComponent";
import { QueueContext } from "../../../provider/queue.provider";

function Home() {
  const currentUser = useContext(UserContext);
  const footage = useContext(FootageContext);
  const queue = useContext(QueueContext);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVideo, setModalVideo] = useState<Video>();
  const slidesToShow = 3;
  
  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.innerHTML = `window.pendo.initialize({
  //     visitor: {
  //       id: "${currentUser?.uid}"
  //     },
  //     account: {
  //       id: "${currentUser?.uid}" 
  //     }
  //   });`;
  //   document.body.appendChild(script);
  //   return 
  // }, [currentUser]);

  const thumbNailVideoClick = (vid: Video) => {
    if (modalVisible) {
      setModalVisible(false);
      setModalVideo(undefined);
    } else {
      setModalVisible(true);
      setModalVideo(vid);
    }
  };

  const carouselQueue = () => {
    const result = [];
    if (!!queue.target) {
      result.push(
        <Spin spinning={true} tip="rendering..." key={queue.target.key}>
          <div className="carousel-item">
            <ThumbNail
              src={queue.target.thumbNail}
              alt={queue.target.renderName}
              object={queue.target}
              thumbNailClick={() => null}
            />
            <div>{queue.target.renderName}</div>
          </div>
        </Spin>
      );
    }
    if (!!queue.queued) {
      for (const item of queue.queued) {
        result.push(
          <Spin spinning={true} tip="render queued..." key={item.key}>
            <div className="carousel-item">
              <ThumbNail
                src={item.thumbNail}
                alt={item.renderName}
                object={item}
                thumbNailClick={() => null}
              />
              <div>{item.renderName}</div>
            </div>
          </Spin>
        );
      }
    }
    return result;
  };

  const carouselFootage = () => {
    return footage.map((video) => {
      return (
        <div className="carousel-item" key={video.id}>
          <ThumbNail
            src={video.thumbNail}
            alt={video.name}
            object={video}
            thumbNailClick={() => thumbNailVideoClick(video)}
          />
          <div
            style={{
              overflowWrap: "break-word",
              marginRight: "10%",
              marginLeft: "10%",
            }}
          >
            {video.name}
          </div>
        </div>
      );
    });
  };

  let greeting = "";
  if (!!currentUser?.firstName) {
    greeting = ", " + currentUser.firstName;
  }

  const NextArrow = (props: any) => {
    const { className, style, onClick, slideCount, currentSlide } = props;
    let element = <RightOutlined />;
    if (currentSlide + slidesToShow === slideCount) {
      element = <></>;
      style.cursor = "auto";
    }
    return (
      <div
        className={className}
        style={{
          ...style,
          color: "black",
          fontSize: "15px",
          lineHeight: "1.5715",
        }}
        onClick={onClick}
      >
        {element}
      </div>
    );
  };

  const PrevArrow = (props: any) => {
    const { className, style, onClick, currentSlide } = props;
    let element = <LeftOutlined />;
    if (currentSlide === 0) {
      element = <></>;
      style.cursor = "auto";
    }
    return (
      <div
        className={className}
        style={{
          ...style,
          color: "black",
          fontSize: "15px",
          lineHeight: "1.5715",
        }}
        onClick={onClick}
      >
        {element}
      </div>
    );
  };

  return (
    <div className="home">
      <Modal
        visible={modalVisible}
        centered
        onOk={() => setModalVisible(false)}
        onCancel={() => setModalVisible(false)}
        footer={[]}
        width="80%"
        // closable={false}
      >
        <VideoComponent video={modalVideo} />
      </Modal>
      <div className="home-header">
        <div>Welcome to your Aquifer Motion Dashboard{greeting}</div>
        <Divider />
      </div>
      <div className="welcome-card">
        <div className="welcome-list">
          <div className="welcome-list-header">
            Download the Aquifer Motion Desktop App.
          </div>
          <p>Start animating today with the Aquifer Motion App. To access the latest downloads, click the link below.</p>
            <Button className="project-heading-btn" type="link" href="http://aquifermotion.com/downloads">
                Get the App 
            </Button>
        </div>
        {/*<img src={welcomeImage} alt="screenshot" />*/}
      </div>

      <div style={{ width: "100%" }}>
        <div className="home-header">
          <div>Recent Renders</div>
        </div>

        <div className="carousel-wrapper">
          <Carousel
            autoplay={false}
            infinite={false}
            arrows={true}
            prevArrow={<PrevArrow />}
            nextArrow={<NextArrow />}
            dots={false}
            slidesToShow={slidesToShow}
            slidesToScroll={1}
          >
            {carouselQueue()}
            {carouselFootage()}
          </Carousel>
        </div>
      </div>
    </div>
  );
}

export default Home;
