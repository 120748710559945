import { useLocation } from "react-router-dom";
import AdminProvider from "../../provider/admin.provider";
import Admin from "./admin/admin";
import Footage from "./footage/footage";
import Home from "./home/home";
import Projects from "./projects/projects";

function DashRouter() {
  const location = useLocation();
  const getComponent = () => {
    switch (location.hash) {
      case "#admin":
        return (
          <AdminProvider>
            <Admin />
          </AdminProvider>
        );
      case "#home":
        return <Home />;
      case "#projects":
        return <Projects />;
      case "#footage":
        return <Footage />;
      default:
        return <Home />;
    }
  };

  return getComponent();
}

export default DashRouter;
