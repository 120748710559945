import "./videoComponent.css";
import axios from "axios";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../firebase/firebase-service";
import { Video } from "../../../models/models";
import { useState } from "react";
import { Button, Popover } from "antd";

interface Props {
  video?: Video;
}
function VideoComponent({ video }: Props) {
  const [shareUrl, setShareUrl] = useState<string>("");
  const [shareLoading, setShareLoading] = useState<boolean>(true);
  const [downloadWaiting, setDownloadWaiting] = useState<boolean>(false);

  const getShareUrl = async () => {
    const filePath = video?.path;
    setShareLoading(true);
    setShareUrl("" as string);
    var getUrl = httpsCallable(functions, "renderShare");
    const url = await getUrl({ filePath });
    setShareUrl(url.data as string);
    setShareLoading(false);
  };

  const downloadVideo = () => {
    if (!!video) {
      setDownloadWaiting(true);
      axios
        .get(video.url, {
          method: "GET",
          responseType: "blob",
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", video.name);
          document.body.appendChild(link);
          link.click();
          setDownloadWaiting(false);
        });
    }
  };

  const shareButton = () => {
    // if (!!video) {
      return (
        <Popover
          style={{ maxWidth: "100px" }}
          placement="top"
          content={
            <div style={{ display: "flex", flexDirection: "column" }}>
              <a href={shareUrl} style={{ marginBottom: "5%" }}>
                {shareUrl}
              </a>
              <CopyToClipboard text={shareUrl}>
                <Button type="primary" loading={shareLoading} disabled={shareLoading}>
                  Copy Shareable Link
                </Button>
              </CopyToClipboard>
            </div>
          }
          trigger="click"
        >
          <Button
            type="primary"
            onClick={() => {
              getShareUrl();
            }}
          >
            Share
          </Button>
        </Popover>
      );
    // } else return <></>;
  };
  
  if(video?.path.includes(".mp4"))
  {
      return (
          <div className="videoBox">
              <video
                  src={video?.url}
                  poster={video?.thumbNail}
                  autoPlay={true}
                  className="video"
                  controls={true}
                  preload="metadata"
                  // controlsList="nodownload"
              />
              <div className="videoActions">
                  <Button
                      type="primary"
                      loading={downloadWaiting}
                      style={{ marginRight: "10px" }}
                      onClick={() => {
                          downloadVideo();
                      }}
                  >
                      Download
                  </Button>
                  {shareButton()}
              </div>
          </div>
      );
  }
    return (
        <div className="videoBox">
            <img src={video?.thumbNail} alt="render thumbnail"/>
            <div className="videoActions">
                <Button
                    type="primary"
                    loading={downloadWaiting}
                    style={{marginRight: "10px"}}
                    onClick={() => {
                        downloadVideo();
                    }}
                >
                    Download
                </Button>
                {shareButton()}
            </div>
            <div className="previewDisclaimer">
                <p>Preview playback is not available for MOV/alpha exports. To view this file, download and import it into your video editing software.</p>
            </div>
        </div>
    );

}

export default VideoComponent;
