import { useContext, useEffect, useState } from "react";
import { Button, Card, Spin, Table } from "antd";
import { Profile, Queue } from "../../../models/models";
import { AdminContext } from "../../../provider/admin.provider";
import { getDatabase, ref, remove } from "firebase/database";
import { app } from "../../../firebase/firebase-service";

function QueuePanel() {
  const adminData = useContext(AdminContext);
  const [queueTable, setQueueTable] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const queue = adminData.queue as Queue;
    const users = adminData.users as Profile[];

    const tableData = [];
    if (!!queue.target && !!queue.target.path) {
      const pathParts = queue.target.path.split("/");
      const uid = pathParts[1];
      const user = users.find((x) => x.uid === uid);
      tableData.push({
        ...queue.target,
        user: user?.email,
        status: "rendering",
      });
    }

    if (!!queue.queued) {
      const queueItems = queue.queued.map((q) => {
        const pathParts = q.path.split("/");
        const uid = pathParts[1];
        console.log(uid);
        const user = users.find((x) => x.uid === uid);
        return {
          ...q,
          user: user?.email,
          status: "queued",
        };
      });
      tableData.push(...queueItems);
    }
    setQueueTable([...tableData]);
    setLoading(false);
  }, [adminData]);

  const columns = [
    {
      title: "User",
      dataIndex: "user",
    },
    {
      title: "Path",
      dataIndex: "path",
    },
    {
      title: "Render Status",
      dataIndex: "status",
    },
    {
      title: "",
      dataIndex: "actions",
      render: (text: string, record: any) => {
        if (record.key !== "target") {
          return <Button onClick={() => cancelItem(record)}>{"Cancel"}</Button>;
        } else {
          return <></>;
        }
      },
    },
  ];

  const cancelItem = (record: any) => {
    console.log(record);
    setLoading(true);
    const db = getDatabase(app);
    const queueRef = ref(db, `render/queue/${record.key}`);
    remove(queueRef).catch((err) => {
      console.log(err);
    });
  };

  const QueueTable = () => {
    return (
      <Table
        columns={columns}
        dataSource={queueTable}
        pagination={{ defaultPageSize: 10 }}
      />
    );
  };

  return (
    <Spin size="large" spinning={loading}>
      <Card title="Render Queue">
        <QueueTable />
      </Card>
    </Spin>
  );
}

export default QueuePanel;
