import { onAuthStateChanged } from "firebase/auth";
import { createContext, useEffect, useState } from "react";
import { FirebaseService, auth } from "../firebase/firebase-service";
import { Profile } from "../models/models";

export const UserContext = createContext<Profile | null>(null);

const UserProvider = ({ children }: any) => {
  const [currentUser, setCurrentUser] = useState<Profile | null>(null);

  useEffect(() => {
    FirebaseService.shared.authSetPersistence();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        FirebaseService.shared.getProfile().then((profile) => {
          setCurrentUser(profile as Profile);
        });
      } else {
        setCurrentUser(null);
      }
    });
  }, []);

  return (
    <UserContext.Provider value={currentUser}>{children}</UserContext.Provider>
  );
};

export default UserProvider;
