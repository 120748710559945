import { getDatabase, ref, onValue } from "firebase/database";
import { collection, onSnapshot } from "firebase/firestore";
import { createContext, useContext, useEffect, useState } from "react";
import { app, firestore } from "../firebase/firebase-service";
import {
  Profile,
  Queue,
  QueueError,
  QueueItem,
  StudioKit,
} from "../models/models";
import { UserContext } from "./user.provider";

export const AdminContext = createContext<any>({});

const AdminProvider = ({ children }: any) => {
  const currentUser = useContext(UserContext);
  const [queue, setQueue] = useState<Queue>({});
  const [studioKits, setStudioKits] = useState<StudioKit[]>([]);
  const [users, setUsers] = useState<Profile[]>([]);

  useEffect(() => {
    if (!!currentUser?.roles.admin) {
      const db = getDatabase(app);
      const queueRef = ref(db, "render");
      onValue(queueRef, (snapshot) => {
        const data = snapshot.val();
        const { errors, queue, target } = data;
        const errorItems = [];
        const queueItems = [];
        for (const id in errors) {
          const { error, render } = errors[id];
          errorItems.push({
            key: id,
            error,
            render,
          } as QueueError);
        }
        for (const id in queue) {
          const { renderName, thumbNail, path } = queue[id];
          queueItems.push({
            key: id,
            renderName,
            thumbNail,
            path,
          } as QueueItem);
        }

        const queueDetails = {
          queued: queueItems,
          errors: errorItems,
        } as Queue;

        queueDetails.target = {
          key: "target",
          path: target?.path,
          renderName: target?.renderName,
          thumbNail: target?.thumbNail,
        } as QueueItem;

        setQueue(queueDetails);
      });
    } else {
      setQueue({});
    }
  }, [currentUser]);

  useEffect(() => {
    if (!!currentUser?.roles.admin) {
      const ref = collection(firestore, "studioKits");
      onSnapshot(ref, {
        next: (snapshot) => {
          const kits: StudioKit[] = [];
          snapshot.forEach((doc) => {
            const kit = {
              name: doc.id,
              ...doc.data(),
            } as StudioKit;
            kits.push(kit);
          });
          setStudioKits([...kits]);
        },
        error: (e) => console.error(e),
      });
    } else {
      setStudioKits([]);
    }
  }, [currentUser]);

  useEffect(() => {
    if (!!currentUser?.roles.admin) {
      const ref = collection(firestore, "users");
      onSnapshot(ref, {
        next: (snapshot) => {
          const userList: Profile[] = [];
          snapshot.forEach((doc) => {
            const user = {
              ...doc.data(),
            } as Profile;
            userList.push(user);
          });
          setUsers([...userList]);
        },
        error: (e) => console.error(e),
      });
    } else {
      setUsers([]);
    }
  }, [currentUser]);

  return (
    <AdminContext.Provider value={{ queue, studioKits, users }}>
      {children}
    </AdminContext.Provider>
  );
};

export default AdminProvider;
