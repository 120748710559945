import { Button, Checkbox, Divider, Layout, Pagination } from "antd";
import { useEffect, useState } from "react";
import { Project, Video } from "../../../../models/models";
import ThumbNail from "../thumbNail";
import "./contentLayout.css";
const { Header, Footer, Content } = Layout;

interface Props {
  title: string;
  itemData: Video[] | Project[];
  thumbNailClick: (arg: any) => void;
  deleteItem: (arg: any) => Promise<void>;
}

function ContentLayout({ title, itemData, thumbNailClick, deleteItem }: Props) {
  const pageSize = 6;
  const [selected, setSelected] = useState<any[]>([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [items, setItems] = useState<any[]>(itemData);

  useEffect(() => {
    setItems(itemData);
    setSelected([]);
    setDeleteLoading(false);
  }, [itemData]);

  const createItems = () => {
    const displayed = items.slice(startIndex, startIndex + pageSize);
    return displayed.map((item) => {
      return (
        <div className="grid-item" key={item.id}>
          <div className="grid-item-content">
            <div className="grid-item-header">
              <Checkbox
                className="aquiferCheck"
                disabled={deleteLoading}
                checked={item.checked}
                onChange={() => onCheck(item)}
              />
              {/* {menu} */}
            </div>
            <div className="grid-item-body">
              <ThumbNail
                src={item.thumbNail}
                alt={item.name}
                object={item}
                thumbNailClick={() => thumbNailClick(item)}
              />
              <div>{item.name}</div>

            </div>
          </div>
        </div>
      );
    });
  };

  const onPageChange = (page: any) => {
    setStartIndex((page - 1) * pageSize);
    setCurrentPage(page);
  };

  const onCheck = (item: Project | Video) => {
    let updateSelected = selected;
    let updateItems = items;
    if (updateSelected.some((x) => x.id === item.id)) {
      var index = updateSelected.indexOf(item);
      updateSelected.splice(index, 1);
    } else {
      if (!updateSelected.some((v) => v.id === item.id)) {
        updateSelected.push(item);
      }
    }
    const vIndex = updateItems.indexOf(item);
    item.checked = item.checked ? false : true;
    updateItems[vIndex] = item;
    setItems([...updateItems]);
    setSelected([...updateSelected]);
  };

  const handleDeleteSelection = async () => {
    setDeleteLoading(true);
    for (const item of selected) {
      await deleteItem(item);
    }
  };

  return (
    <Layout className="layout">
      <Header>
        <div className="layout-header" style={{ display: "flex" }}>
          <div className="title">{title}</div>
          <div>
            <Button
              type="primary"
              onClick={handleDeleteSelection}
              disabled={selected.length < 1}
              loading={deleteLoading}
            >
              Delete {selected.length > 0 ? `${selected.length} items` : ""}
            </Button>
          </div>
        </div>
        <Divider />
      </Header>
      <Content>
        <div className="layout-content">{createItems()}</div>
      </Content>
      <Footer>
        <Pagination
          current={currentPage}
          defaultCurrent={currentPage}
          pageSize={pageSize}
          onChange={onPageChange}
          total={items.length}
          style={{ marginTop: "1%" }}
        />
      </Footer>
    </Layout>
  );
}

export default ContentLayout;
