import { Tabs } from "antd";
import QueuePanel from "./queuePanel";
import StudioKits from "./studioKits";
const { TabPane } = Tabs;

function Admin() {
  return (
    <div style={{ marginTop: "1%" }}>
      <Tabs defaultActiveKey="1" className="contentSection">
        <TabPane tab="StudioKit Access" key="1">
          <StudioKits />
        </TabPane>
        <TabPane tab="Render Queue" key="2">
          <QueuePanel />
        </TabPane>
        <TabPane tab="Render Errors" key="3">
          {/* <ErrorList dataSource={errors} /> */}
        </TabPane>
        <TabPane tab="Render Server" key="4">
          {/* <ErrorList dataSource={errors} /> */}
        </TabPane>
      </Tabs>
    </div>
  );
}

export default Admin;
