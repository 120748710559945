import { Layout } from "antd";
import "./dashboard.css";
import SideNav from "./sidenav/sideNav";
import DashRouter from "./dashRouter";
import { useState } from "react";
import Modal from "antd/lib/modal/Modal";
import CreateProject from "./createProject/createProject";

const { Content, Footer, Sider } = Layout;

function Dashboard() {
  const [modalVisible, setModalVisible] = useState(false);
  const newProject = () => {
    if (modalVisible) {
      setModalVisible(false);
    } else {
      setModalVisible(true);
    }
  };

  // const [collapsed, setCollapsed] = useState(false);
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Modal
        visible={modalVisible}
        centered
        onOk={() => setModalVisible(false)}
        footer={null}
        width="80%"
        closable={false}
      >
        <CreateProject setModalVisible={setModalVisible} />
      </Modal>
      <Sider
        theme="light"
        // collapsible
        // collapsed={collapsed}
        // onCollapse={() => setCollapsed(!collapsed)}
      >
        <SideNav newProject={newProject} />
      </Sider>
      <Layout className="site-layout">
        <Content className="dashboard-content">
          <DashRouter />
        </Content>
        <Footer className="dashboard-footer">Aquifer Motion ©2024</Footer>
      </Layout>
    </Layout>
  );
}

export default Dashboard;
