import { addDoc, collection } from "firebase/firestore";
import { useState, useEffect, useContext } from "react";
import { firestore } from "../../../firebase/firebase-service";
import { Video } from "../../../models/models";
import { Button, Col, Input, Pagination, Row } from "antd";
import { UserContext } from "../../../provider/user.provider";
import "./createProject.css";
import ThumbNail from "../common/thumbNail";
import { MotionboxService } from "../../../motionbox/motionbox.service";
import { FootageContext } from "../../../provider/footage.provider";

interface Props {
  setModalVisible: (arg: boolean) => void;
}

function CreateProject({ setModalVisible }: Props) {
  const pageSize = 12;
  const currentUser = useContext(UserContext);
  const userVideos = useContext(FootageContext);
  const uid = currentUser?.uid as string;
  const [videos, setVideos] = useState<Video[]>([]);
  const [selected, setSelected] = useState<Video[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [projectName, setProjectName] = useState("My New Project");

  useEffect(() => {
    setVideos([...userVideos]);
  }, [userVideos]);

  const thumbNailClick = (i: number) => {
    const checked = videos[i].checked ? false : true;
    videos[i].checked = checked;
    setSelected(videos.filter((x) => x.checked));
    setVideos([...videos]);
  };

  const footage = () => {
    return videos.map((video, i) => {
      const className = video.checked ? "checked" : "unchecked";
      return (
        <div className={className}>
          <div className="footage-grid-item">
            <ThumbNail
              src={video.thumbNail}
              alt={video.name}
              object={video}
              thumbNailClick={() => thumbNailClick(i)}
            />
            <span>{video.name}</span>
          </div>
        </div>
      );
    });
  };

  const onPageChange = (page: any) => {
    setStartIndex((page - 1) * pageSize);
    setCurrentPage(page);
  };

  const displayed = () => {
    const elements = footage();
    return elements.slice(startIndex, startIndex + pageSize);
  };

  const onCreate = async () => {
    const mappedVideos = selected.map((v) => {
      return {
        duration: 1,
        height: 1080,
        image: "",
        url: "",
        video_files: [
          {
            file_type: "video/mp4",
            height: 1080,
            link: v.url,
            quality: "hd",
            width: 1920,
          },
        ],
        video_pictures: [
          {
            picture: "",
          },
        ],
        width: 1920,
      };
    });

    const collectionRef = collection(firestore, `users/${uid}/videoEdits`);
    const result = await addDoc(collectionRef, {
      editName: projectName,
      rendered: false,
      videoUrl: "",
      videos: mappedVideos,
    });
    const projectId = result.id;
    setModalVisible(false);
    MotionboxService.openMotionbox(projectId, mappedVideos);
  };

  const onCancel = () => {
    setModalVisible(false);
    setStartIndex(0);
    setCurrentPage(1);
    setSelected([]);
    const unchecked = videos.map((v: Video) => {
      v.checked = false;
      return v;
    });
    setVideos([...unchecked]);
  };

  const onProjectName = (e: any) => {
    setProjectName(e.target.value);
  };

  return (
    <div className="create-project">
      <div className="create-project-header">
        <div>Select the footage to import into your new project:</div>
      </div>
      <div className="footage-grid">{displayed()}</div>
      <div className="create-project-footer">
        <Row gutter={16}>
          <Col span={12}>
            <Pagination
              current={currentPage}
              defaultCurrent={currentPage}
              pageSize={pageSize}
              onChange={onPageChange}
              total={videos.length}
              style={{ marginTop: "1%" }}
            />
          </Col>
          <Col span={6}>
            <Input
              name="create"
              addonBefore="Project Name:"
              defaultValue={projectName}
              onChange={onProjectName}
            />
          </Col>
          <Col span={3}>
            <Button type="primary" onClick={onCancel}>
              Cancel
            </Button>
          </Col>
          <Col span={3}>
            <Button type="primary" onClick={onCreate}>
              Create Project
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default CreateProject;
