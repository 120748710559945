import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../firebase/firebase-service";
import { ProjectVideo } from "../models/models";

const key = process.env.REACT_APP_MOTIONBOX_API_KEY;
const token = `Bearer ${key}`;

class MotionboxService {
  static openMotionbox(projectId: string, videos: ProjectVideo[]) {
    window.openMotionbox({
      token,
      env: "production",
      userId: projectId,
      uiConfig: {
        dashboard: false,
        header: {
          avatars: false,
          sharing: false,
        },
        sidebar: {
          logo: false,
          active: "videos",
          videos: [videos],
        },
      },
      onDone: async (link: any) => {
        const docRef = doc(firestore, `users/${projectId}/videoEdits`);
        await updateDoc(docRef, {
          rendered: true,
          videoUrl: link,
        });
      },
    });
  }
}

export { MotionboxService };
