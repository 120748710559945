import { Input, Button, Checkbox, notification } from "antd";
import { useContext, useEffect, useState } from "react";
import logo from "../../images/lockup_vert_black.png";
import { Link, Navigate } from "react-router-dom";
import { FirebaseService } from "../../firebase/firebase-service";
import "./auth.css";
import { UserContext } from "../../provider/user.provider";

interface ISignupProps {
  confirmLoading?: boolean;
  isLoggedIn?: boolean;
  redirect?: boolean;
  email?: string;
  password?: string;
  confirmPassword?: string;
  firstName?: string;
  lastName?: string;
  terms?: boolean;
}

function SignUp() {
  const [signupProps, setSignupProps] = useState<Partial<ISignupProps>>({
    redirect: false,
    confirmLoading: false,
  });

  const user = useContext(UserContext);

  useEffect(() => {
    if (!!user) {
      setSignupProps({ redirect: true });
    }
  }, [user]);

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setSignupProps({ ...signupProps, [name]: value });
  };

  const handleSignup = () => {
    const { email, password, confirmPassword, firstName, lastName, terms } =
      signupProps;
    if (!(email && password)) {
      notification.open({
        type: "error",
        message: "Please fill in email. and both password fields",
      });
      return;
    }
    if (password !== confirmPassword) {
      notification.open({
        type: "error",
        message: "Please ensure both passwords match",
      });
      return;
    }
    if (!(firstName && lastName)) {
      notification.open({
        type: "error",
        message: "Please fill in first and last name",
      });
      return;
    }
    if (!terms) {
      notification.open({
        type: "error",
        message: "Please accept terms and conditions",
      });
      return;
    }

    const profileData: any = {
      firstName: firstName,
      lastName: lastName,
    };

    FirebaseService.shared
      .signup(email, password, profileData)
      .then((result) => {
        if (result) {
          setSignupProps({ redirect: true });
        }
      })
      .catch((result) => {
        if (result.code === "auth/email-already-in-use") {
          result.message =
            "You indicated you are a new customer, but an account already exists with the email " +
            email;
        }
        notification.open({
          type: "error",
          message: result.message,
        });
        setSignupProps({ ...signupProps, confirmLoading: false });
      });
  };
  if (signupProps.redirect) {
    return <Navigate to="/dashboard" replace={true} />;
  }
  return (
    <div className="Auth">
      <div className="isoAuthContent">
        <Link to={"/"}>
          {String.fromCharCode(8592) + "  back to Aquifer login"}
        </Link>
        <img src={logo} alt="logo" className="logo" />
        <div className="isoAuthForm">
          <div className="isoInputWrapper">
            <Input
              name="firstName"
              size="large"
              placeholder="First Name"
              value={signupProps.firstName}
              onChange={(e: any) => handleInputChange(e)}
            />
          </div>
          <div className="isoInputWrapper">
            <Input
              name="lastName"
              size="large"
              placeholder="Last Name"
              value={signupProps.lastName}
              onChange={(e: any) => handleInputChange(e)}
            />
          </div>
          <div className="isoInputWrapper">
            <Input
              name="email"
              size="large"
              placeholder="Email"
              value={signupProps.email}
              onChange={(e: any) => handleInputChange(e)}
            />
          </div>

          <div className="isoInputWrapper">
            <Input
              name="password"
              size="large"
              type="password"
              placeholder="Password"
              value={signupProps.password}
              onChange={(e: any) => handleInputChange(e)}
            />
          </div>

          <div className="isoInputWrapper">
            <Input
              name="confirmPassword"
              size="large"
              type="password"
              placeholder="Confirm Password"
              value={signupProps.confirmPassword}
              onChange={(e: any) => handleInputChange(e)}
            />
          </div>
          <div
            className="isoInputWrapper linkStyle"
            style={{ marginBottom: "50px" }}
          >
            <Checkbox
              name="terms"
              type="checkbox"
              checked={signupProps.terms}
              onChange={(e: any) => handleInputChange(e)}
            >
              {"I agree with the "}
              <a
                className="linkStyle"
                href={"https://www.aquifermotion.com/terms-conditions"}
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms and Conditions
              </a>
              {" and "}
              <a
                href={"https://www.aquifermotion.com/privacy-policy"}
                target="_blank"
                rel="noopener noreferrer"
                className="linkStyle"
              >
                Privacy Policy
              </a>
            </Checkbox>
          </div>

          <div className="isoInputWrapper">
            <Button type="primary" onClick={handleSignup}>
              Sign Up
            </Button>
          </div>

          <Link to="/signin" className="linkStyle align-center">
            Already have an account?
          </Link>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
