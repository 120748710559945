import { collection, onSnapshot } from "firebase/firestore";
import { createContext, useContext, useEffect, useState } from "react";
import { firestore } from "../firebase/firebase-service";
import { Video } from "../models/models";
import { UserContext } from "./user.provider";

export const FootageContext = createContext<Video[]>([]);

const FootageProvider = ({ children }: any) => {
  const currentUser = useContext(UserContext);
  const [footage, setFootage] = useState<Video[]>([]);

  useEffect(() => {
    if (!!currentUser) {
      const uid = currentUser?.uid as string;
      const dir = `users/${uid}/recordings`;
      const ref = collection(firestore, dir);
      const unsubscribe = onSnapshot(ref, (snapshot) => {
        let videoFiles = [];
        if (!snapshot.empty) {
          const docs = snapshot.docs;
          for (const recData of docs) {
            if (recData.exists()) {
              const id = recData.id;
              const recording = recData.data();
              if (
                recording.fileName &&
                  (recording.fileName.toString().endsWith(".mp4") || recording.fileName.toString().endsWith(".mov"))
              ) {
                const row = {
                  id,
                  name: recording.fileName,
                  path: recording.storagePath,
                  url: recording.mediaLink,
                  created: recording.createdAt,
                  checked: false,
                  thumbNail: recording.thumbNail,
                } as Video;
                videoFiles.push(row);
              }
            }
          }
          videoFiles.sort(
            (a, b) =>
              new Date(b.created).getTime() - new Date(a.created).getTime()
          );
        }
        setFootage(videoFiles);
        console.log(videoFiles);
      });
      return unsubscribe;
    } else {
      setFootage([]);
    }
  }, [currentUser]);

  return (
    <FootageContext.Provider value={footage}>
      {children}
    </FootageContext.Provider>
  );
};

export default FootageProvider;
