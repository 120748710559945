import { ref, deleteObject } from "firebase/storage";
import { useState, useContext } from "react";
import { storage } from "../../../firebase/firebase-service";
import { Video } from "../../../models/models";
import { Modal } from "antd";
import ContentLayout from "../common/contentLayout/contentLayout";
import VideoComponent from "../common/videoComponent";
import { FootageContext } from "../../../provider/footage.provider";
import "./footage.css";

function Footage() {
  const footage = useContext(FootageContext);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVideo, setModalVideo] = useState<Video>();

  const thumbNailClick = (vid: Video) => {
    if (modalVisible) {
      setModalVisible(false);
      setModalVideo(undefined);
    } else {
      setModalVisible(true);
      setModalVideo(vid);
    }
  };

  const deleteItem = async (video: Video) => {
    const videoRef = ref(storage, video.path);
    let imgRef;
    if(video.path.includes(".mp4")){
      imgRef = ref(storage, video.path.replace(".mp4", ".png"));
    }
    if(video.path.includes(".mov")) {
      imgRef = ref(storage, video.path.replace(".mov", ".png"));
    }
    await deleteObject(videoRef);
    
    if(imgRef){
      await deleteObject(imgRef);
    }
  };

  return (
    <div className="footage">
      <Modal
        visible={modalVisible}
        centered
        onOk={() => setModalVisible(false)}
        onCancel={() => setModalVisible(false)}
        footer={[]}
        width="80%"
        // closable={false}
      >
        <VideoComponent video={modalVideo} />
      </Modal>
      <ContentLayout
        title={"Exports"}
        itemData={footage}
        thumbNailClick={thumbNailClick}
        deleteItem={deleteItem}
      />
    </div>
  );
}

export default Footage;
