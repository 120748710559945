import "./thumbNail.css";
import { PictureOutlined } from "@ant-design/icons";

interface Props {
  src?: string;
  alt: string;
  object: any;
  thumbNailClick: (arg: any) => void;
}

function ThumbNail({ src, alt, object, thumbNailClick }: Props) {
  const getThumbNail = () => {
    if (!src) {
      return (
        <div className="placeholder" onClick={() => thumbNailClick(object)}>
          <PictureOutlined className="icon" />
        </div>
      );
    } else {
      return (
        <img
          className="thumb-img"
          src={src}
          alt={alt}
          onClick={() => thumbNailClick(object)}
        />
      );
    }
  };

  return getThumbNail();
}

export default ThumbNail;
