import { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Popconfirm, Row, Select, Table } from "antd";
import { TeamOutlined, DeleteOutlined } from "@ant-design/icons";
import { functions } from "../../../firebase/firebase-service";
import { Profile, StudioKit } from "../../../models/models";
import { httpsCallable } from "firebase/functions";
import { AdminContext } from "../../../provider/admin.provider";
import "./studioKits.css";

const { Option } = Select;

const columns = [
  {
    title: "Client ID",
    dataIndex: "name",
  },
  {
    title: () => <TeamOutlined />,
    dataIndex: "userCount",
  },
];

const AddUserAccessCallable = httpsCallable(functions, "addStudioKitAccess");
const RemoveUserAccessCallable = httpsCallable(
  functions,
  "removeStudioKitAccess"
);

function StudioKits() {
  const adminData = useContext(AdminContext);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const [studioKits, setStudioKits] = useState<StudioKit[]>([]);
  const [kitList, setkitList] = useState<any[]>([]);
  const [selectedKit, setSelectedKit] = useState<StudioKit>();
  const [users, setUsers] = useState<Profile[]>([]);
  const [selectedAddUser, setSelectedAddUser] = useState("");
  const [addUserLoading, setAddUserLoading] = useState(false);

  useEffect(() => {
    const kits = adminData.studioKits as StudioKit[];
    const kitList = kits.map((k) => {
      return {
        key: k.name,
        name: k.name,
        userCount: k.users.length,
      };
    });
    setkitList([...kitList]);
    setStudioKits([...adminData.studioKits]);
    setUsers([...adminData.users]);
    if (!!selectedKit) {
      const update = kits.find((k) => k.name === selectedKit.name);
      if (!!update) {
        setSelectedKit(update);
      } else {
        setSelectedKit(undefined);
      }
    }
  }, [adminData, selectedKit]);

  const selectRow = (record: any) => {
    const selected = studioKits.find((x) => x.name === record.key);
    if (selected) {
      setSelectedKit(selected);
    }
    setSelectedRowKeys([record.key]);
  };

  const StudioKitList = () => {
    return (
      <Table
        rowSelection={{
          type: "radio",
          selectedRowKeys,
        }}
        columns={columns}
        dataSource={kitList}
        onRow={(record) => ({
          onClick: () => {
            selectRow(record);
          },
        })}
        pagination={{ defaultPageSize: 10 }}
      />
    );
  };

  const removeUserFromKit = async (email: string) => {
    // TODO: Error or success popup
    await RemoveUserAccessCallable({
      studioKitId: selectedKit?.name,
      email,
    });
  };

  const addUserToKit = async (user: string) => {
    setAddUserLoading(true);
    const email = user.split(",")[1];
    // TODO: Error or success popup
    await AddUserAccessCallable({ studioKitId: selectedKit?.name, email });
    setSelectedAddUser("");
    setAddUserLoading(false);
  };

  const UserAccessDropdown = () => {
    const dataSource = formatUsersDropdown();
    return (
      <div className="user-select">
        <Select
          showSearch
          value={selectedAddUser}
          style={{ width: "50%" }}
          placeholder="Select a user"
          optionFilterProp="children"
          onSelect={setSelectedAddUser}
          // TODO: fix this
          // filterOption={(input, option) =>
          //   option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
          //   0
          // }
        >
          {dataSource}
        </Select>
        <Button
          type="primary"
          loading={addUserLoading}
          disabled={!selectedAddUser}
          onClick={() => addUserToKit(selectedAddUser)}
          style={{ maxWidth: "20%", marginLeft: "3%" }}
        >
          Add Access
        </Button>
      </div>
    );
  };

  const formatUsersDropdown = () => {
    if (!users || users.length < 0) {
      return [];
    }
    let available = users.filter((x) => !selectedKit?.users.includes(x.email));
    available = available.sort((a, b) => (a.email > b.email ? 1 : -1));
    return available.map((usr, i) => {
      var email = usr.email ? usr.email : "";
      var created = usr.created ? usr.created : "";
      var val = `${usr.uid},${email},${created}`;
      return (
        <Option key={i} value={val}>
          {email}
        </Option>
      );
    });
  };

  const KitUserTable = () => {
    if (!selectedKit) {
      return <>No StudioKit selected.</>;
    }

    const { users = [] } = selectedKit;
    const formattedUsers = users.map((email) => {
      return {
        email,
        key: email,
      };
    });
    const columns = [
      {
        title: "E-mail",
        dataIndex: "email",
      },
      {
        dataIndex: "operation",
        render: (text: string, record: any) =>
          users.length >= 1 ? (
            <Popconfirm
              title="Confirm remove access?"
              onConfirm={() => removeUserFromKit(record.key)}
            >
              <DeleteOutlined />
            </Popconfirm>
          ) : null,
      },
    ];

    return (
      <>
        <UserAccessDropdown />
        <Table
          dataSource={formattedUsers}
          columns={columns}
          pagination={{ defaultPageSize: 9 }}
        />
      </>
    );
  };

  return (
    <div>
      <Row gutter={8}>
        <Col span={12}>
          <Card title="StudioKits">
            <StudioKitList />
          </Card>
        </Col>
        <Col span={12}>
          <Card
            title={
              selectedKit?.name
                ? `${selectedKit.name} Users`
                : "No Kit Selected"
            }
          >
            <KitUserTable />
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default StudioKits;
