import { initializeApp } from "firebase/app";
import { getAnalytics, setUserId, logEvent } from "firebase/analytics";
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  User,
  sendEmailVerification,
  updateProfile,
  updateEmail,
  setPersistence,
  browserLocalPersistence,
  browserSessionPersistence,
} from "firebase/auth";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";

import { Profile } from "../models/models";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_apiKey,
  authDomain: process.env.REACT_APP_FIREBASE_authDomain,
  databaseURL: process.env.REACT_APP_FIREBASE_databaseURL,
  projectId: process.env.REACT_APP_FIREBASE_projectId,
  storageBucket: process.env.REACT_APP_FIREBASE_storageBucket,
  messagingSenderId: process.env.REACT_APP_FIREBASE_messagingSenderId,
  appId: process.env.REACT_APP_FIREBASE_appId,
  measurementId: process.env.REACT_APP_FIREBASE_measurementId,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);
const database = getDatabase(app);
const analytics = getAnalytics(app);
const functions = getFunctions(app);
const storage = getStorage(app);

class FirebaseService {
  static shared: FirebaseService = new FirebaseService();
  async login(email: string, password: string): Promise<any> {
    return signInWithEmailAndPassword(auth, email, password)
      .then(async (cred) => {
        await this.getProfile();
        if (cred && cred.user) {
          setUserId(analytics, cred.user.uid);
          this.updateSigninTime(cred.user);
          logEvent(analytics, "webApp_login", {
            method: "email",
            uid: cred.user.uid,
          });
        }
        return true;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  updateSigninTime(user: User) {
    const ref = doc(firestore, `users/${user.uid}`);
    setDoc(
      ref,
      {
        signin: user.metadata.lastSignInTime,
      },
      { merge: true }
    );
  }

  doSendEmailVerification() {
    if (auth.currentUser) {
      var url = process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT
        ? process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT
        : "";
      sendEmailVerification(auth.currentUser, { url });
    }
  }

  async signup(email: string, password: string, profileData: Profile) {
    var signup = await createUserWithEmailAndPassword(auth, email, password)
      .then(async (cred) => {
        var user = cred.user;
        if (user && user.uid) {
          setUserId(analytics, user.uid);
          logEvent(analytics, "webApp_signUp", {
            method: "email",
            uid: user.uid,
            time: user.metadata.creationTime,
          });
          var username = `${profileData.firstName} ${profileData.lastName}`;
          await updateProfile(user, { displayName: username });
          if (user.metadata && user.metadata.creationTime) {
            profileData.created = user.metadata.creationTime;
          }
          await this.upsertProfile(user.uid, email, profileData);
        } else {
          return Promise.reject();
        }
      })
      .then(() => {
        return ""; // this.getProfile();
      });
    return signup;
  }

  async upsertProfile(
    uid: string,
    email: string,
    profileData: Partial<Profile>
  ) {
    const ref = doc(firestore, `users/${uid}`);
    return setDoc(ref, {
      uid: uid,
      email: email,
      roles: { paid: false, admin: false },
      ...profileData,
    });
  }

  async getProfile() {
    var user = auth.currentUser;
    if (user) {
      const uid = user.uid;
      const email = user.email;
      const ref = doc(firestore, `users/${user.uid}`);
      return getDoc(ref).then((doc) => {
        if (doc.exists()) {
          const { created, firstName, lastName, notifications, roles } =
            doc.data();
          return {
            uid,
            email,
            firstName,
            lastName,
            created,
            roles,
            notifications,
          } as Profile;
        }
        return null;
      });
    }
  }

  currentUser() {
    return auth.currentUser;
  }

  async updateProfile(email: string, firstName: string, lastName: string) {
    var result = false;
    var user = auth.currentUser;
    var displayName = `${firstName} ${lastName}`;
    if (user) {
      await updateEmail(user, email);
      await updateProfile(user, { displayName: displayName });
      result = true;
    }
    return result;
  }

  async isAdmin() {
    let user = auth.currentUser;
    if (user) {
      return user
        .getIdTokenResult(true)
        .then((res) => {
          return res.claims.admin;
        })
        .catch((error) => {
          console.log(error);
          return false;
        });
    }
    return false;
  }

  authSetPersistence() {
    setPersistence(
      auth,
      process.env.NODE_ENV === "test"
        ? browserSessionPersistence
        : browserLocalPersistence
    );
  }
}

export {
  FirebaseService,
  analytics,
  app,
  auth,
  database,
  firestore,
  functions,
  storage,
};
