import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "../provider/user.provider";

const ProtectedRoute = ({ redirectPath = "/", children }: any) => {
  const user = useContext(UserContext);
  if (!user) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};

export default ProtectedRoute;
