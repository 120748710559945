import { collection, onSnapshot } from "firebase/firestore";
import { createContext, useContext, useEffect, useState } from "react";
import { firestore } from "../firebase/firebase-service";
import { Project, ProjectData } from "../models/models";
import { UserContext } from "./user.provider";

export const ProjectContext = createContext<Project[]>([]);

const ProjectProvider = ({ children }: any) => {
  const currentUser = useContext(UserContext);
  const [projects, setProjects] = useState<Project[]>([]);

  useEffect(() => {
    if (!!currentUser) {
      const uid = currentUser?.uid as string;
      const dir = `users/${uid}/videoEdits`;
      const ref = collection(firestore, dir);
      const unsubscribe = onSnapshot(ref, (snapshot) => {
        let userProjects = [];
        if (!snapshot.empty) {
          for (var doc of snapshot.docs) {
            const editId = doc.id;
            const projectId = doc.id;
            const edit = doc.data();
            const { editName, videoUrl, rendered, videos } = edit;
            let thumbNail = edit?.thumbNail;

            const projectData = {
              editId,
              editName,
              rendered,
              videoUrl,
              videos,
            } as ProjectData;

            userProjects.push({
              id: projectId,
              name: editName,
              checked: false,
              projectData,
              thumbNail,
            } as Project);
          }
        }
        setProjects(userProjects);
      });
      return unsubscribe;
    } else {
      setProjects([]);
    }
  }, [currentUser]);

  return (
    <ProjectContext.Provider value={projects}>
      {children}
    </ProjectContext.Provider>
  );
};

export default ProjectProvider;
