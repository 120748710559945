import { Input, Button, Checkbox, notification } from "antd";
import { useContext, useEffect, useState } from "react";
import logo from "../../images/lockup_vert_black.png";
import { Link, Navigate } from "react-router-dom";
import { FirebaseService } from "../../firebase/firebase-service";
import "./auth.css";
import { UserContext } from "../../provider/user.provider";

interface ISigninProps {
  redirect: boolean;
  confirmLoading?: boolean;
  email?: string;
  password?: string;
}

function SignIn() {
  const [signinProps, setSigninProps] = useState<Partial<ISigninProps>>({});
  const user = useContext(UserContext);

  useEffect(() => {
    if (!!user) {
      setSigninProps({ redirect: true });
    }
  }, [user]);

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setSigninProps({ ...signinProps, [name]: value });
  };

  const handleLogin = () => {
    const { email, password } = signinProps;
    if (!(email && password)) {
      notification.open({
        type: "error",
        message: "Please fill in email and password",
      });
      return;
    }
    setSigninProps({ ...signinProps, confirmLoading: true });
    FirebaseService.shared
      .login(email!, password!)
      .then((result) => {
        if (result) {
          setSigninProps({ redirect: true });
        }
      })
      .catch((result: any) => {
        switch (result.code) {
          case "auth/user-not-found":
            result.message =
              "We cannot find an account with that email address.";
            break;
          case "auth/invalid-email":
            result.message = "Invalid email address.";
            break;
          case "auth/wrong-password":
            result.message = "Password is incorrect.";
            break;
          default:
            break;
        }
        notification.open({ type: "error", message: result.message });
        setSigninProps({ ...signinProps, confirmLoading: false });
      });
  };

  if (signinProps.redirect) {
    return <Navigate to="/dashboard" replace={true} />;
  }
  return (
    <div className="Auth">
      <div className="isoAuthContent">
        <a
          href="https://www.aquifermotion.com"
          className="linkStyle align-left"
        >
          {String.fromCharCode(8592) + "  back to Aquifer main page"}
        </a>
        <img src={logo} alt="logo" className="logo" />
        <div className="isoAuthForm">
          <div className="isoInputWrapper">
            <Input
              name="email"
              size="large"
              placeholder="Email"
              value={signinProps?.email}
              onChange={(e: any) => handleInputChange(e)}
            />
          </div>

          <div className="isoInputWrapper">
            <Input
              name="password"
              size="large"
              type="password"
              placeholder="Password"
              value={signinProps.password}
              onChange={(e: any) => handleInputChange(e)}
            />
          </div>

          <div className="isoInputWrapper">
            <Button type="primary" onClick={handleLogin}>
              Sign In
            </Button>
          </div>

          <div className="isoInputWrapper">
            <Checkbox>Remember Me</Checkbox>
          </div>

          <Link to="/forgot" className="linkStyle align-center">
            Forgot Password
          </Link>

          <Link to="/signup" className="linkStyle align-center">
            Create Account
          </Link>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
