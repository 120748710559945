import { Routes, Route } from "react-router-dom";
import "./App.css";
import ForgotPassword from "./components/auth/forgotPassword";
import SignIn from "./components/auth/signin";
import SignUp from "./components/auth/signup";
import Dashboard from "./components/dashboard/dashboard";
import ProtectedRoute from "./components/protectedRoute";
import FootageProvider from "./provider/footage.provider";
import ProjectProvider from "./provider/project.provider";
import QueueProvider from "./provider/queue.provider";
import UserProvider from "./provider/user.provider";

function App() {
  return (
    <UserProvider>
      <FootageProvider>
        <ProjectProvider>
          <QueueProvider>
            <div className="App">
              <Routes>
                <Route path="/" element={<SignIn />} />
                <Route path="/signin" element={<SignIn />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/forgot" element={<ForgotPassword />} />

                <Route
                  path="/dashboard"
                  element={
                    <ProtectedRoute>
                      <Dashboard />
                    </ProtectedRoute>
                  }
                />
                <Route path="*" element={<SignIn />} />
              </Routes>
            </div>
          </QueueProvider>
        </ProjectProvider>
      </FootageProvider>
    </UserProvider>
  );
}

export default App;
